// ---------------------------------------------------------
// Custom Functions for Front-End
// ---------------------------------------------------------
$(function () {
  // Start your function from next line here
  $('.jsShowPass').on('click', function() {
    $(this).toggleClass('active');
    if($(this).hasClass('active')) {
      $(this).next().attr('type','text');
    } else {
      $(this).next().attr('type','password');
    }
  })
  $('.jsMoveToNext input').on('input', function() {
    if ($(this).val().length >= $(this).attr('maxlength')) {
      $(this).next('input').focus();
    }
  });
});
